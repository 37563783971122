<template>
    <div class="container mt-5 px-2">
      <article class="message is-danger" v-if="!token">
        <div class="message-body">
          You have not signed in yet. Please go back to <a href="/">home page</a> to sign in first.
        </div>
      </article>
      <div v-if="token">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><router-link :to="'/products-sales'">Products</router-link></li>
            <li>
              <a href="#" aria-current="page">
                <span>{{nsIdIndex}}</span> &nbsp;-&nbsp;
                <span>{{nsSku}}</span>
              </a>
            </li>
          </ul>
        </nav>
  
        <div class="tabs is-toggle">
          <ul>
            <li :class="{'is-active': routeName == 'ProductSales'}">
              <router-link :to="'/product-sales/' + nsIdIndex">
                <span>Sales</span>
              </router-link>
            </li>
            <li :class="{'is-active': routeName == 'ProductInventory'}">
              <router-link :to="'/product-inventory/' + nsIdIndex">
                <span>Inventory</span>
              </router-link>
            </li>
          </ul>
        </div>
  
        <div class="mt-3">
          <div v-if="waiting">
            <span class="icon is-medium is-size-4">
              <i class="fas fa-spinner fa-pulse"></i>
            </span>
          </div>
          <div v-else>
            <div>
              <div>
                <h1 class="title is-5 mt-6">Options</h1>

                <div class="field">
                  <label class="label">Products</label>
                  <div class="control">
                    <div class="tags">
                      <span v-for="(p, i) in products" :key="'products-' + i">
                        <span class="tag is-light is-medium">
                          {{p.name}}&nbsp;
                          <button class="delete is-small" v-if="i != 0" @click="removeProduct(i)"></button>
                        </span>&nbsp;
                      </span>
                    </div>
                  </div>
                </div>

                <div class="field has-addons">
                  <p class="control">
                    <span class="select">
                      <select v-model="selectedClass">
                        <option v-for="(c, i) in allClasses" :key="'classes-option-' + i">
                          {{c}}
                        </option>
                      </select>
                    </span>
                  </p>
                  <p class="control">
                    <span class="select">
                      <select v-model="newProduct">
                        <option v-for="(p, i) in productsByClassName[selectedClass]" :key="'products-option-' + i" :value="p">
                          {{p.name}}
                        </option>
                      </select>
                    </span>
                  </p>
                  <p class="control">
                    <a class="button" @click="$event => addProduct()" :class="{'is-loading': loadingData}">
                      Add
                    </a>
                  </p>
                </div>
  
                <div class="field">
                  <label class="label">Date Range</label>
                  <div class="control">
                    <label class="checkbox">
                      <input type="checkbox" v-model="dateRangeEnabled">
                      Enabled
                    </label>
                  </div>
                  <div class="control date-range-container" v-if="dateRangeEnabled">
                    <div class="columns">
                      <div class="column is-narrow from-date-picker">
                        <datepicker v-model="dateRangeFromDate" :inline="true" name="fromdate"></datepicker>
                      </div>
                      <div class="column to-date-picker">
                        <datepicker v-model="dateRangeToDate" :inline="true" name="todate"></datepicker>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="field">
                  <label class="label">Show Sales</label>
                  <div class="control">
                    <label class="checkbox">
                      <input type="checkbox" v-model="showSales">
                      Show
                    </label>
                  </div>
                </div>
  
              </div>
            </div>
            
  
            <div>
              <div>
                <h1 class="title is-5 mt-6">Charts</h1>
                <div class="field is-grouped is-grouped-multiline">
                  <p class="control">
                    <span class="select">
                      <select v-model="otherOptions.chartTheme">
                        <option v-for="(ctm, i) in chartThemeOptions" :key="'chart-theme-option-' + i">
                          {{ctm}}
                        </option>
                      </select>
                    </span>
                  </p>
                  <p class="control">
                    <input class="input" type="number" placeholder="Chart Height" style="width:140px" v-model.number="otherOptions.chartHeight">
                  </p>
                </div>
                
                <div class="chart-container" v-if="salesChartOption" :style="{'height': otherOptions.chartHeight + 'px'}">
                  <v-chart autoresize :option="salesChartOption" :ref="'sales-chart'" :update-options="{notMerge: true}"  :theme="otherOptions.chartTheme"/>
                </div>
                <div class="mt-4">
                  <div class="field is-grouped is-grouped-multiline">
                    <p class="control">
                      <input class="input" type="text" placeholder="Title" style="width:600px" v-model="otherOptions.title">
                    </p>
                    <p class="control">
                      <span class="select">
                        <select v-model="otherOptions.chartType">
                          <option>bar</option>
                          <option>line</option>
                          <option>smooth line</option>
                        </select>
                      </span>
                    </p>
                    <p class="control">
                      <input class="input" type="number" placeholder="Inventory Max" style="width:150px" v-model.number="otherOptions.yAxis0Max">
                    </p>
                    <p class="control" v-show="showSales">
                      <input class="input" type="number" placeholder="Sales Max" style="width:120px" v-model.number="otherOptions.yAxis1Max">
                    </p>
                  </div>
                </div>
              </div>

            </div>
  
            <div>
              <div class="table-section">
                <h1 class="title is-5 mt-6">Table</h1>
                <a class="button" @click="pullInventory">Pull inventory</a>
                <table class="table is-fullwidth is-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th v-for="(p, i) in products" :key="'col-header-' + i" class="has-text-centered">
                        <span>{{p.name}}</span><br/>
                        <span class="is-size-7 has-text-grey">CA, NY, TX</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(d, i) in dates.toReversed()" :key="'row-' + i">
                      <td>{{i + 1}}</td>
                      <td>{{d}}</td>
                      <td v-for="(p, j) in products" :key="'row-product-' + i + '-' + j" class="has-text-right">
                        <div class="columns">
                          <div class="column">
                            <span v-if="inventoriesData[p.itemId]">
                              <span>{{inventoriesData[p.itemId][d] ? inventoriesData[p.itemId][d].inventory : 0}}</span>&nbsp;
                              <span v-if="inventoriesData[p.itemId][d] && inventoriesData[p.itemId][d].usLandedCost">({{inventoriesData[p.itemId][d].usLandedCost}})</span>
                            </span>
                            <br/>
                            <span v-if="inventoriesData[p.itemId]" class="is-size-7 has-text-grey">
                              <span>{{inventoriesData[p.itemId][d] ? inventoriesData[p.itemId][d].caInventory : 0}}</span>,
                              <span>{{inventoriesData[p.itemId][d] ? inventoriesData[p.itemId][d].nyInventory : 0}}</span>,
                              <span>{{inventoriesData[p.itemId][d] ? inventoriesData[p.itemId][d].txInventory : 0}}</span>
                            </span>
                            <span v-if="inventoriesData[p.itemId]" class="is-size-7 has-text-grey">
                              (<span>{{inventoriesData[p.itemId][d] ? inventoriesData[p.itemId][d].caLandedCost : 0}}</span>,
                              <span>{{inventoriesData[p.itemId][d] ? inventoriesData[p.itemId][d].nyLandedCost : 0}}</span>,
                              <span>{{inventoriesData[p.itemId][d] ? inventoriesData[p.itemId][d].txLandedCost : 0}}</span>)
                            </span>
                          </div>
                          <div class="column" v-if="showSales && salesData[p.itemId]">
                            <span>{{salesData[p.itemId][d] ? salesData[p.itemId][d] : 0}}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>  
  
            </div>
          </div>
        </div>
        <div v-if="error" class="notification is-danger is-light">
          <button class="delete" @click="error=''"></button>
          {{error}}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Datepicker from 'vuejs-datepicker'
  import dateFormat from 'dateformat'
  import Vue from 'vue'
  
  
  export default {
    name: 'ProductSales',
    components: {
      Datepicker
    },
    data () {
      return {
        error: '',
        waiting: false,
        loadingData: false,
        sales: {},
        inventories: {},
        newProduct: {name: ''},
        allProducts: [],
        products: [],
        allClasses: [],
        selectedClass: null,
        productsByClassName: {},
        dateRangeEnabled: false,
        dateRangeFromDate: new Date('2021-01-01T12:00:00Z'),
        dateRangeToDate: new Date(),
        showSales: true,
        otherOptions: {
          title: '',
          chartType: 'bar',
          yAxis0Max: null,
          yAxis1Max: null,
          chartHeight: 600,
          chartTheme: 'default',
        },
        chartThemeOptions: ['default', 'light', 'dark', 'vintage', 'westeros', 'essos', 'wonderland', 'walden', 'chalk', 'infographic', 'macarons', 'roma', 'shine', 'purple-passion', 'halloween', 'ovilia-green'],
      }
    },
    computed: {
      server () {
        return this.$store.state.config.server
      },
      token () {
        return this.$store.state.user.token
      },
      routeName () {
        return this.$route.name
      },
      nsIdIndex () {
        return this.$route.params.nsIdIndex
      },
      nsSku () {
        if (!this.products.length) {
          return ''
        }
        for (var p of this.products) {
          if (p.itemId == this.nsIdIndex) {
            return p.name
          }
        }
        return ''
      },
      salesData () {
        var data = {}
        for (var pid of Object.keys(this.sales)) {
          data[pid] = {}
          for (var s of this.sales[pid]) {
            if (!data[pid][s.tranDate]) {
              data[pid][s.tranDate] = 0
            }
            data[pid][s.tranDate] += s.quantity
          }
        }
        return data
      },
      inventoriesData () {
        var data = {}
        for (var pid of Object.keys(this.inventories)) {
          data[pid] = {}
          for (var i of this.inventories[pid]) {
            data[pid][i.date] = i
          }
        }
        return data
      },
      dates () {
        var dates = new Set()
        for (var p of this.products) {
          var ss = this.sales[p.itemId]
          if (ss) {
            for (var s of ss) {
              dates.add(s.tranDate)
            }
          }
          var ii = this.inventories[p.itemId]
          if (ii) {
            for (var i of ii) {
              dates.add(i.date)
            }
          }
        }
        dates = Array.from(dates)
        dates.sort((a, b) => a.localeCompare(b))
        if (!dates.length) {
          return dates
        }

        var firstDate = this.dateRangeEnabled ? this.dateRangeFromDate.toISOString().substring(0, 10) : dates[0]
        var lastDate = this.dateRangeEnabled ? this.dateRangeToDate.toISOString().substring(0, 10) : dates[dates.length - 1]
        var dates = []
        var date = new Date(firstDate + 'T12:00:00Z')
        while (date.toISOString().substring(0, 10) <= lastDate) {
          dates.push(date.toISOString().substring(0, 10))
          date = new Date(date.getTime() + 24 * 3600 * 1000)
        }
        return dates
      },
      commonOption () {
        var yAxis0Max = Number(this.otherOptions.yAxis0Max)
        var yAxis1Max = Number(this.otherOptions.yAxis1Max)
        var yAxis = [{type: 'value', name: 'Inventory', min: 0, max: (isNaN(yAxis0Max) || !yAxis0Max) ? null : yAxis0Max}]
        if (this.showSales) {
          yAxis.push({type: 'value', name: 'Sales', min: 0, max: (isNaN(yAxis0Max) || !yAxis1Max) ? null : yAxis1Max})
        }
        return {
          tooltip: {
            trigger: 'axis'
          },
          toolbox: {
            feature: {
              restore: {},
              saveAsImage: {}
            }
          },
          yAxis: yAxis,
        }
      },
      xAxis () {
        var option = {type: 'category'}
        if (!this.dates.length) {
          return option
        }
        option.data = this.dates
        return option
      },
      salesSeries () {
        var series = []
        for (var p of this.products) {
          var chartType = this.otherOptions.chartType.includes('line') ? 'line' : 'bar'
          var smooth = this.otherOptions.chartType.includes('smooth')
          var s1 = {
            name: p.name + ' Inventory',
            type: chartType,
            smooth: smooth,
            yAxisIndex: 0,
            data: [],
          }
          var inventory = this.inventoriesData[p.itemId]
          if (inventory) {
            for (var d of this.xAxis.data) {
              var i = inventory[d]
              s1.data.push((i && i.inventory) ? i.inventory : null)
            }
          }
          series.push(s1)
          if (this.showSales) {
            var s2 = {
              name: p.name + ' Sales',
              type: chartType,
              smooth: smooth,
              yAxisIndex: 1,
              data: [],
            }
            var sales = this.salesData[p.itemId]
            if (sales) {
              for (var d of this.xAxis.data) {
                s2.data.push(sales[d] ? sales[d] : 0)
              }
            }
            series.push(s2)
          }
        }
        return series
      },
      salesChartOption () {
        if (!this.products.length) {
          return {}
        }
        var option = {...this.commonOption}
        var names = []
        for (var p of this.products) {
          names.push(p.name + ' Inventory')
          if (this.showSales) {
            names.push(p.name + ' Sales')
          }
        }
        option.legend = {
          data: names,
          top: 30
        }
        option.title = {
          text: '',
          left: 'center',
        }
        option.xAxis = this.xAxis
        option.series = this.salesSeries
        option.dataZoom = [
          {start: 0, end: 100},
          {type: 'inside'}
        ]
        return option
      },
    },
    methods: {
      makeInventory (inventory) {
        if (inventory.caInventory || inventory.nyInventory || inventory.txInventory) {
          var totalCount = 0
          var totalCost = 0
          if (inventory.caInventory && inventory.caLandedCost) {
            totalCount += inventory.caInventory
            totalCost += inventory.caInventory * inventory.caLandedCost
          }
          if (inventory.nyInventory && inventory.nyLandedCost) {
            totalCount += inventory.nyInventory
            totalCost += inventory.nyInventory * inventory.nyLandedCost
          }
          if (inventory.txInventory && inventory.txLandedCost) {
            totalCount += inventory.txInventory
            totalCost += inventory.txInventory * inventory.txLandedCost
          }
          inventory.usLandedCost = totalCost / totalCount
          inventory.usLandedCost = Math.round(inventory.usLandedCost * 100) / 100

          inventory.caLandedCost = inventory.caLandedCost ? (Math.round(inventory.caLandedCost * 100) / 100) : 0
          inventory.nyLandedCost = inventory.nyLandedCost ? (Math.round(inventory.nyLandedCost * 100) / 100) : 0
          inventory.txLandedCost = inventory.txLandedCost ? (Math.round(inventory.txLandedCost * 100) / 100) : 0
        }
        return inventory
      },
      getDataForProducts (itemIds) {
        var promises = []
        for (let itemId of itemIds) {
          var p1 = this.$http.get(this.server + '/myapp/get-sales-by-ns-id-index/' + itemId + '/').then(resp => {
            Vue.set(this.sales, itemId, resp.body)
          }, err => {
            this.error = err.body
          })
          promises.push(p1)
          var p2 = this.$http.get(this.server + '/myapp/get-inventory-by-ns-id-index/' + itemId + '/').then(resp => {
            Vue.set(this.inventories, itemId, resp.body.map(this.makeInventory))
          }, err => {
            this.error = err.body
          })
          promises.push(p2)
        }
        this.loadingData = true
        var vm = this
        Promise.all(promises).then(data => {
          vm.loadingData = false
        }, err => {
          vm.loadingData = false
        })
      },
      getProducts () {
        this.$http.get(this.server + '/myapp/get-sales-for-products/').then(resp => {
          this.allProducts = resp.body.map(p => {
            var ss = p.itemSku.split(':')
            return {...p, name: ss[ss.length-1].trim()}
          }).sort((a, b) => a.name.localeCompare(b.name))
          var allClasses = new Set()
          var productsByClassName = {}
          for(var p of this.allProducts) {
            allClasses.add(p.className)
            if (!productsByClassName[p.className]) {
              productsByClassName[p.className] = [{name: 'All'}]
            }
            productsByClassName[p.className].push(p)
            if (p.itemId == this.nsIdIndex) {
              this.selectedClass = p.className
              this.products.push(p)
            }
          }
          this.allClasses = [...allClasses].sort()
          this.productsByClassName = productsByClassName
          this.getDataForProducts([this.nsIdIndex])
        }, err => {
          this.error = err.body
        })
      },
      addProduct () {
        this.products.push(this.newProduct)
        var missing = []
        for (var p of this.products) {
          if (!this.sales[p.itemId]) {
            missing.push(p.itemId)
          }
        }
        if (missing.length) {
          this.getDataForProducts(missing)
        }
      },
      removeProduct (i) {
        this.products.splice(i, 1)
      },
      pullInventory () {
        var data = {idIndex: this.nsIdIndex}
        this.$http.post(this.server + '/myapp/pull-inventory-for-product/', data)
      },
    },
    mounted () {
      this.$nextTick(function(){
        this.getProducts()
      })
    },
  }
  </script>
  
  <style scoped>
  .chart-container {
    height: 600px;
    width: 100%;
  }
  .date-range-container {
    margin-top: 10px;
  }
  .from-date-picker {
    padding-right: 0px;
  }
  .to-date-picker {
    padding-left: 0px;
  }
  .table-section {
    overflow-x: auto;
  }
  </style>
  